<template>
  <div class="limiter">
    <div class="container-login">
      <div class="wrap-login">
        <div class="login-form-title p-b-26">EventsMV</div>
        <div class="txt1 p-b-26 text-center">Exhibitor Portal</div>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
          ></v-text-field>
          <v-text-field
            v-model="password"
            :rules="justPasswordRules"
            label="Current Password"
            type="password"
            required
          ></v-text-field>
          <v-text-field
            @keypress="checkPassword"
            v-model="new_password"
            :rules="passwordRules"
            label="New Password"
            type="password"
            required
          ></v-text-field>
          <el-alert
            :title="passwordStrength"
            :type="passwordStatus"
            effect="dark"
            :closable="false"
          ></el-alert>
          <v-text-field
            v-model="confirm_password"
            :rules="confirmPasswordRules"
            label="Confirm New Password"
            type="password"
            required
          ></v-text-field>
        </v-form>

        <div class="row">
          <div class="col-lg-6 col-sm-12">
            <v-btn
              title
              color="primary"
              class="mr-0"
              elevation="0"
              @click="signIn"
              :disabled="
                empty(password) ||
                empty(confirm_password) ||
                empty(new_password) ||
                empty(email)
              "
            >
              UPDATE
              <v-icon color="white">mdi-content-save-outline</v-icon>
            </v-btn>
          </div>
          <div class="col-lg-6 col-sm-12" style="align: right">
            <v-btn
              title
              color="warning"
              class="mr-0"
              elevation="0"
              @click="back_to_login"
            >
              BACK TO LOGIN
              <v-icon color="white">mdi-exit-to-app</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <v-overlay absolute :value="loading" :opacity="0.7">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import Vue from "vue";
import { Alert } from "element-ui";
Vue.use(Alert);
export default {
  created() {
    this.email = localStorage.getItem("er_public_email");
  },
  data() {
    return {
      valid: true,
      loading: false,
      password: "",
      confirm_password: "",
      passwordStatus: "error",
      new_password: "",
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length > 8) || "Password must morre than 8 characters",
        (v) =>
          /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(
            v
          ) ||
          "Password must contain at least 1 Special Character, 1 Numeric and 1 Alpha",
      ],
      justPasswordRules: [(v) => !!v || "Password is required"],
      confirmPasswordRules: [
        (v) =>
          v == this.new_password ||
          "New Password and Confirmation Password should be the same",
      ],
      passwordStrength: "Weak Password",
      email: localStorage.getItem("er_public_email"),
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  methods: {
    signIn() {
      this.$refs.form.validate();
      this.post();
    },
    post() {
      this.$refs.form.validate();
      console.log(this.valid);
      if (!this.valid) return;
      this.loading = true;
      let f = {
        email: this.email,
        password: this.password,
        new_password: this.new_password,
        confirm_password: this.confirm_password,
      };
      this.api("auth/update-password", f, this.exhibitorApi)
        .then((response) => {
          this.loading = false;
          console.log(response);
          if (response.status == true) {
            this.$router.push("/sign-in");
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    checkPassword() {
      let strongPassword = new RegExp(
        "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
      );
      let mediumPassword = new RegExp(
        "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
      );
      if (strongPassword.test(this.new_password)) {
        this.passwordStrength = "Strong Password";
        this.passwordStatus = "success";
      } else if (mediumPassword.test(this.new_password)) {
        this.passwordStrength = "Medium Password";
        this.passwordStatus = "warning";
      } else {
        this.passwordStatus = "error";
        this.passwordStrength = "Weak Password";
      }
    },
    back_to_login() {
      document.location.href = "/sign-in";
    },
  },
  computed: {
    new_password_check() {
      let strongPassword = new RegExp(
        "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
      );
      let mediumPassword = new RegExp(
        "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
      );
      if (strongPassword.test(this.new_password)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.passwordStrength = "Strong";
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.passwordStatus = "success";
        return true;
      } else if (mediumPassword.test(this.new_password)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.passwordStrength = "Medium";
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.passwordStatus = "warning";
        return true;
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.passwordStatus = "error";
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.passwordStrength = "Weak";
        return false;
      }
    },
  },
  name: "ChangePassword",
};
</script>
<style>
/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

@font-face {
  font-family: Poppins-Regular;
  src: url("../../assets/fonts/poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: Poppins-Medium;
  src: url("../../assets/fonts/poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: Poppins-Bold;
  src: url("../../assets/fonts/poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url("../../assets/fonts/poppins/Poppins-SemiBold.ttf");
}

/*//////////////////////////////////////////////////////////////////
[ RESTYLE TAG ]*/

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  font-family: Poppins-Regular, sans-serif;
}

/*---------------------------------------------*/
a {
  font-family: Poppins-Regular;
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
  color: #6aa0fe;
  color: -webkit-linear-gradient(left, #21d4fd, #032dff);
  color: -o-linear-gradient(left, #21d4fd, #032dff);
  color: -moz-linear-gradient(left, #21d4fd, #032dff);
  color: linear-gradient(left, #21d4fd, #032dff);
}

/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

p {
  font-family: Poppins-Regular;
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

/*---------------------------------------------*/
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

/*//////////////////////////////////////////////////////////////////
[ Utility ]*/
.txt1 {
  font-family: Poppins-Regular;
  font-size: 13px;
  color: #666666;
  line-height: 1.5;
}

.txt2 {
  font-family: Poppins-Regular;
  font-size: 13px;
  color: #333333;
  line-height: 1.5;
}

/*//////////////////////////////////////////////////////////////////
[ login ]*/

.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #f2f2f2;
}

.wrap-login {
  width: 390px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding: 77px 55px 33px 55px;

  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
}

/*------------------------------------------------------------------
[ Form ]*/

.login-form {
  width: 100%;
}

.login-form-title {
  display: block;
  font-family: Poppins-Bold;
  font-size: 30px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
}
.login-form-title i {
  font-size: 60px;
}

/*------------------------------------------------------------------
[ Button ]*/
.container-login-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 13px;
}

.wrap-login-form-btn {
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
  border-radius: 25px;
  overflow: hidden;
  margin: 0 auto;
}

.login-form-bgbtn {
  position: absolute;
  z-index: -1;
  width: 300%;
  height: 100%;
  background: #6aa0fe;
  background: -webkit-linear-gradient(
    right,
    #21d4fd,
    #032dff,
    #21d4fd,
    #032dff
  );
  top: 0;
  left: -100%;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login-form-btn {
  font-family: Poppins-Medium;
  font-size: 15px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
}

.wrap-login-form-btn:hover .login-form-bgbtn {
  left: 0;
}

/*------------------------------------------------------------------
[ Responsive ]*/

@media (max-width: 576px) {
  .wrap-login {
    padding: 77px 15px 33px 15px;
  }
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}
</style>
